// src/pages/account.js
import React, { useContext,useState } from 'react';
import { Router} from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { Link } from "gatsby"
import {
  Card,
  CardContent,
  CardImage,
  Column,
  Columns,
  Container,
  Content,
  Hero,
  HeroHeader,
  HeroBody,
  Tabs,
  TabList,
  Tab,
  TabLink,
  Title,
  Subtitle
} from 'bloomer';
import Layout from '../components/layout';
import SEO from '../components/seo';


import { Svc802WanNyanAccounts } from './svc-802-wan-nyan-accounts'
import { ThemeContext, getOppositeTheme } from '../contexts/theme';
const Home = ({ user }) => {
  if( !user){
    return <p>Hi, friend!</p>
  }

  return <p>Hi, {user.name ? user.name : "friend"}!</p>
}
const Settings = () => <p>Settings</p>

const Billing = () => <p>Billing</p>




const Members = props => {
  const [user, setUser] = useState(null);

  const { theme } = useContext(ThemeContext);

  const { data, location } = props;
  const { title: siteTitle, keywords } = data.site.siteMetadata;
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  if(null == user){
    getProfile().then(u=>setUser(u))
  }
  return(
    <Layout location={location}>
      <SEO title="Members" keywords={keywords || []} />
      <Hero isColor={theme} className="is-fullheight-with-navbar">
        <HeroHeader>


          <Tabs isFullWidth>
            <Container>
                <TabList>


                    {/* <Tab isActive><Link to="/members/">Home</Link></Tab>
                    <Tab><Link to="/members/settings/">Settings</Link></Tab>
                    <Tab><Link to="/members/billing/">Billing</Link></Tab> */}
                    <Tab isActive><Link to="/members/802-wan-nyan/">八王子わんにゃんクラブ</Link></Tab>
                    <Tab><TabLink onClick={(e)=>{ 
                      logout()
                      e.preventDefault()
                    }}>Sign Out</TabLink></Tab>
                </TabList>
            </Container>
          </Tabs>
          <Router>
            <Home path="/members/" user={user} />
            <Settings path="/members/settings" />
            <Billing path="/members/billing" />
            <Svc802WanNyanAccounts path="/members/802-wan-nyan" />
          </Router>
        </HeroHeader>
        <HeroBody>
          <Container>
            {/* <nav>
              <Link to="/members/">Home</Link>{" "}
              <Link to="/members/settings/">Settings</Link>{" "}
              <Link to="/members/billing/">Billing</Link>{" "}
              <Link to="/members/802-wan-nyan/">八王子わんにゃんクラブ</Link>{" "}
              <a
                href="#logout"
                onClick={e => {
                  logout()
                  e.preventDefault()
                }}
              >
                Log Out
              </a>
            </nav>
            <Router>
              <Home path="/members/" user={user} />
              <Settings path="/members/settings" />
              <Billing path="/members/billing" />
              <Svc802WanNyanAccounts path="/members/802-wan-nyan" />
            </Router> */}
           </Container>
         </HeroBody>
       </Hero>
     </Layout>
  );
}

// export class Members extends React.Component {
//   constructor(props){
//     super(props)
//     this.data = props.data;
//     this.location = props.localtion;
//     this.title = data.site.siteMetadata.siteTitle;
//     this.keywords =  data.site.siteMetadata.keywords;
// //  const { title: siteTitle, keywords } = data.site.siteMetadata;
//     this.state ={
//       user:null,
// //      theme : useContext(ThemeContext)
//     }
//   }


//   render() {
//     if (!isAuthenticated()) {
//       login()
//       return <p>Redirecting to login...</p>
//     }
  
//     if(null == this.state.user){
//       getProfile().then(user=>this.setState({user:user}))
//     }
    
//     return (
//     <Layout location={location}>
//       <SEO title="About" keywords={keywords || []} />
//       <Hero isColor={theme} isFullHeight>
//         <HeroBody>
//           <Container>
//             <nav>
//               <Link to="/members/">Home</Link>{" "}
//               <Link to="/members/settings/">Settings</Link>{" "}
//               <Link to="/members/billing/">Billing</Link>{" "}
//               <Link to="/members/802-wan-nyan/">八王子わんにゃんクラブ</Link>{" "}
//               <a
//                 href="#logout"
//                 onClick={e => {
//                   logout()
//                   e.preventDefault()
//                 }}
//               >
//                 Log Out
//               </a>
//             </nav>
//             <Router>
//               <Home path="/members/" user={this.state.user} />
//               <Settings path="/members/settings" />
//               <Billing path="/members/billing" />
//               <Svc802WanNyanAccounts path="/members/802-wan-nyan" />
//             </Router>
//           </Container>
//         </HeroBody>
//       </Hero>
//     </Layout>
//     )
//   }
// }

export default Members

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        keywords
        author
      }
    }
  }
`;